<template>
    <div class="layer17 flex-col">
        <div class="group10 flex-col">
          <div class="group11 flex-row">
            <span class="word24">产品</span> <span class="info20">方案</span>
            <span class="txt14">服务</span> <span class="word25">案例</span>
            <span class="word26">联系我们</span>
          </div>
          <div class="group12 flex-row">
            <div class="outer12 flex-col justify-between">
              <span class="word27" @click="toProPage(13)">松云WMS</span>
              <span class="word28" @click="toProPage(10)">松云ERP</span>
            </div>
            <div class="outer13 flex-col justify-between">
              <span class="word29" @click="toOthers(31)">线上电商</span>
              <span class="word30" @click="toOthers(31)">智能立库</span>
            </div>
            <span class="word31" @click="toOthers(21)">服务体系</span>
            <span class="info21" @click="toOthers(31)">客户案例</span>
            <span class="txt15">联系电话:&nbsp;&nbsp;020&nbsp;-&nbsp;85596056</span>
          </div>
          <div class="group13 flex-row">
            <div class="box6 flex-col justify-between">
              <span class="word32" @click="toProPage(11)">松云CRM</span>
              <span class="info22" @click="toProPage(12)">松云MALL</span>
              <span class="info23" @click="toProPage(14)">松云TMS</span>
            </div>
            <div class="box7 flex-col justify-between">
              <span class="info24" @click="toOthers(31)">营销获客</span>
              <span class="txt16" @click="toOthers(31)">跨境运输</span>
            </div>
            <div class="box8 flex-col justify-between">
              <span class="word33"
                >联系地址:&nbsp;&nbsp;天河北路365号寰城中心广场2103</span
              >
              <span class="info25">邮箱:&nbsp;&nbsp;support&#64;syinov.com</span>
            </div>
          </div>
          <span class="word34" @click="toProPage(15)">松云SCM</span>
          <div class="page-copyright">
            <div class="content">
              <a href="https://beian.miit.gov.cn/" target="_blank" class="cn">
                <span class="label">版权所有</span>
                <span class="label">© 2022 </span>广州松云信息科技有限公司<span class="label-1">粤ICP备2022027695号-1</span>
              </a>
            </div>
          </div>
          <div class="group14 flex-col"></div>
        </div>
      </div>
</template>

<script>
export default {
  props: {
    // 头部的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      constants: {},
    };
  },
  methods: {
    onClick_1() {
      alert(1);
    },
    onClick_2() {
      alert(1);
    },
    onClick_3() {
      alert(1);
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        // case 1: // 产品介绍
        //   url = "/product/erp";
        //   break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
    toProPage(index) {// 跳转产品介绍页面
      let url = "";
      // index的类型 10-ERP 11-CRM 12-MALL-熊猫游轮 13-MALL-吉利云商城 
      // 14-WMS 15-TMS-黄埔文冲 16-TMS-白云机场 17-SCM
      switch (index) {
        case 10: //ERP
          url = "/product/erp";
          break;
        case 11: // CRM
          url = "/product/crm";
          break;
        case 12: // MALL-熊猫游轮
          url = "/product/mall";
          break;
        // case 13: // MALL-吉利云商城
        //   url = "/product/mall";
        //   break;
        case 13: // WMS
          url = "/product/wms";
          break;
        case 14: //TMS-黄埔文冲
          url = "/product/tms";
          break;
        // case 16: //MS-白云机场
        //   url = "/product/tms";
        //   break;
        case 15: //SCM
          url = "/product/scm";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    topFunction() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    toOthers(index){
      let url = "";

      switch (index) {
        case 21: //服务体系
          url = "/system";
          break;
        case 31: // 典型案例
          url = "/instance";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.layer17 {
  height: 526px;
  background-color: rgba(38, 38, 38, 1);
  width: 1920px;
  position: absolute;
  bottom: 0;
}

.group10 {
  width: 1520px;
  height: 362px;
  margin: 81px 0 0 201px;
}

.group11 {
  width: 1015px;
  height: 36px;
  margin-left: 128px;
}

.word24 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info20 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt14 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word25 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word26 {
  width: 87px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 21px;
  letter-spacing: 1px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 36px;
  text-align: left;
  margin-left: 388px;
}

.group12 {
  width: 1138px;
  height: 56px;
  margin: 20px 0 0 128px;
}

.outer12 {
  width: 73px;
  height: 56px;
}

.word27 {
  width: 73px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}
.word27:hover, .word28:hover, .word29:hover, 
.word30:hover, .word31:hover, .info21:hover, 
.word32:hover, .info22:hover, .info23:hover,
.info24:hover,.txt16:hover, .word34:hover {
  color: rgba(37, 184, 47, 1);
}
.word28 {
  width: 64px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin-top: 8px;
}

.outer13 {
  width: 67px;
  height: 56px;
  margin-left: 121px;
}

.word29 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.word30 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
  cursor: pointer;
}

.word31 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin-left: 87px;
}

.info21 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  cursor: pointer;
  text-align: left;
  margin-left: 87px;
}

.txt15 {
  width: 220px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 16px 0 0 359px;
}

.group13 {
  width: 1262px;
  height: 88px;
  margin: 8px 0 0 128px;
}

.box6 {
  width: 77px;
  height: 88px;
}

.word32 {
  width: 68px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  cursor:pointer;
  text-align: left;
}

.info22 {
  width: 77px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin-top: 8px;
}

.info23 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin-top: 8px;
}

.box7 {
  width: 67px;
  height: 56px;
  margin-left: 117px;
}

.info24 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.txt16 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
  cursor: pointer;
}

.box8 {
  width: 334px;
  height: 84px;
  margin-left: 667px;
}

.word33 {
  width: 345px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info25 {
  width: 230px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word34 {
  width: 68px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  margin: 8px 0 0 128px;
}


.group14 {
  width: 1520px;
  height: 1px;
  background: url(../assets/main/split.png)
    0px 0px no-repeat;
  margin-top: 16px;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.page-copyright {
  .content{
    margin: 81px 0 0 0px;

    .cn {
      display: inline-block;
      text-decoration: none;
      color: #fff;

      .label {
        margin-right: 20px;
      }

      .label-1 {
        margin-left: 20px;
      }
    }
  }
}
</style>