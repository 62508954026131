import { getToken, setToken, removeToken } from '@/utils/auth'


export function isLogin() {
    let token = getToken();
    return (token != undefined && token != null && token != "")
}

export function setLogin(token) {
    setToken(token);
}

export function getLogin() {
  let token = getToken();
  return token;
}


export function removeLogin() {
    removeToken();
}
/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";   
            }
        }
    }
    return result
}

// 数据合并
export function mergeRecursive(source, target) {
    for (var p in target) {
      try {
        if (target[p].constructor == Object) {
          source[p] = mergeRecursive(source[p], target[p]);
        } else {
          source[p] = target[p];
        }
      } catch (e) {
        source[p] = target[p];
      }
    }
    return source;
  };

  // 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}