import request from '@/utils/request'

// 登录
export const login = () => {
    return request({
        url: `/sys/get_anonymous_usersinfo`,
        method: 'get',
    })
}

// 获取用户信息
export const getUserInfo = () => {
    return request({
        url: `/sys/get_userinfo`,
        method: 'get',
    })
}

// 获取消息列表
export const getMsgList = (query) => {
    return request({
        url: `/sys/get_customer_message`,
        method: 'get',
        params: query
    })
}

// 获取消息列表
export const uploadImg = (data,token) => {
    return request({
        url: `/sys/upload?token=${token}`,
        method: 'post',
        data: data
    })
}