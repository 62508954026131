<template>
  <div>
  <div class="group1 flex-col">
    <div class="group2 flex-row">
      <a href="https://www.syinov.com/" target="_blank">
      <img
        class="img1"
        referrerpolicy="no-referrer"
        @click="toNewPage(0)"
        src="@/assets/main/icon.png"
      /></a>
      
      <span
        class="info1"
        :class="type == 0 ? 'info-sel' : ''"
        @click="toNewPage(0)"
        >主页</span
      >
      <div id="sel-menu">
        <span
          class="info2"
          :class="type == 1 ? 'info-sel' : ''"
          @click="toNewPage(1)"
          >产品介绍</span
        >
        <!-- <ul class="ul-menu">
          <li class="li-menu el-font" @click="toProPage(10)">ERP</li>
          <li class="li-menu el-font" @click="toProPage(11)">CRM</li>
          <li class="li-menu el-font" @click="toProPage(12)">MALL</li>
          <li class="li-menu el-font" @click="toProPage(13)">MALL-吉利云商城</li>
          <li class="li-menu el-font" @click="toProPage(13)">WMS</li>
          <li class="li-menu el-font" @click="toProPage(14)">TMS</li>
          <li class="li-menu el-font" @click="toProPage(16)">TMS-白云机场</li>
          <li class="li-menu el-font" @click="toProPage(15)">SCM</li>
        </ul> -->
        <div class="nav-box4 flex-col">
          <div class="nav-group1">
            <div class="nav-bd3 flex-row justify-between" @click="toProPage(10)">
              <span class="nav-info3">松云ERP</span>
              <div class="nav-mod4 flex-col"></div>
            </div>
            <div class="nav-bd3a flex-row justify-between" @click="toProPage(13)">
            <span class="nav-word6">松云WMS</span>
             <div class="nav-mod4a flex-col"></div>
            </div>
            <div class="nav-bd3a flex-row justify-between" @click="toProPage(11)">
            <span class="nav-word7">松云CRM</span>
            <div class="nav-mod4b flex-col"></div>
            </div>
            <div class="nav-bd3a flex-row justify-between" @click="toProPage(14)">
            <span class="nav-word8">松云TMS</span>
            <div class="nav-mod4c flex-col"></div>
            </div>
            <div class="nav-bd3a flex-row justify-between" @click="toProPage(12)">
            <span class="nav-txt2">松云OMS</span>
            <div class="nav-mod4d flex-col"></div>
            </div>
            <div class="nav-bd3a flex-row justify-between" @click="toProPage(15)">
            <span class="nav-info4">松云SCM</span>
            <div class="nav-mod4e flex-col"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-dropdown trigger="click" hide-on-click="true" tabindex="3" @command="handleCommand">
        <span
          class="info2"
          :class="type == 1 ? 'info-sel' : ''"
        >
          产品介绍
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown"  >
          <el-dropdown-item class="el-font" command="a">ERP</el-dropdown-item>
          <el-dropdown-item class="el-font" command="b">CRM</el-dropdown-item>
          <el-dropdown-item class="el-font" command="c">MALL-熊猫游轮</el-dropdown-item>
          <el-dropdown-item class="el-font" command="d">MALL-吉利云商城</el-dropdown-item>
          <el-dropdown-item class="el-font" command="e">WMS</el-dropdown-item>
          <el-dropdown-item class="el-font" command="e">TMS-黄埔文冲</el-dropdown-item>
          <el-dropdown-item class="el-font" command="e">TMS-白云机场</el-dropdown-item>
          <el-dropdown-item class="el-font" command="e">SCM</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <span
        class="info3"
        :class="type == 2 ? 'info-sel' : ''"
        @click="toNewPage(2)"
        >服务体系</span
      >
      <span
        class="word1"
        :class="type == 3 ? 'info-sel' : ''"
        @click="toNewPage(3)"
        >典型案例</span
      >
      <span
        class="info4"
        :class="type == 4 ? 'info-sel' : ''"
        @click="toNewPage(4)"
        >关于松云</span
      >
      <!-- <button class="wrap1 flex-col" @click="toNewPage(5)">
        <span class="txt1">预约注册</span>
      </button> -->
    </div>
    <div class="outer5 flex-col">
      <div class="group5 flex-col">
        <!-- <div class="outer6 flex-col"></div>
            <span class="info11">在线咨询</span> -->
        <div class="outer8 flex-col" @click="showSendMsg">
          <div class="mod6 flex-col justify-between">
            <div class="outer6-1 flex-col" :class="{ 'has-msg': hasMsg }"></div>
            <span class="word7">在线咨询</span>
          </div>
        </div>
        <div class="outer8 flex-col">
          <div class="mod6 flex-col justify-between">
            <div class="outer7 flex-col"></div>
            <span class="word7">电话咨询</span>
          </div>
        </div>
        <!-- @click="toNewPage(5)" -->
        <!-- <div class="outer8 flex-col" >
          <div class="mod6 flex-col justify-between">
            <div class="layer3 flex-col"></div>
            <span class="word7">免费试用</span>
          </div>
        </div> -->
        <div class="outer8 flex-col" id="gotoTop" @click="topFunction">
          <div class="mod6 flex-col justify-between">
            <div class="layer3-1 flex-col"></div>
            <span class="word7">回到顶部</span>
          </div>
        </div>
      </div>
    </div>

    <div class="msg-send" v-if="showMsg">
      <div class="msg-head">
        <div>松云科技</div>
        <div @click="showMsg = false" style="cursor: pointer">
          <img src="@/assets/images/msg/down.png" />
        </div>
      </div>
      <div class="msg-info" ref="msgInfo">
        <div ref="msgConnet">
          <div class="manager-qrcode">
            <img :src="manager_qrcode"/>
          </div>
          <div>如有需要，请加客服微信</div>
          <div v-for="(item,index) in msgList" :key="index" :id="'chatItem_' + index">
            <div v-if="item.msgtype == 'login'" class="login-msg">{{item.content}}</div>
            <div v-if="item.to == selfId && item.msgtype == 'text'" class="login-receive">
              <img class="head-img" :src="maanger_thumb_avatar" />
              <div class="text">{{item.content}}</div>
            </div>
            <div v-if="item.to == selfId && item.msgtype == 'image'" class="login-receive">
              <img class="head-img" :src="maanger_thumb_avatar" />
              <div class="text">
                <img class="text-img" :src="getUrl(item.content)"/>
              </div>
            </div>
            <div v-if="item.to == manager_id && item.msgtype == 'text'" class="login-send">
              <div class="text">{{item.content}}</div>
              <img src="@/assets/images/head-sculpture.png" />
            </div>
            <div v-if="item.to == manager_id && item.msgtype == 'image'" class="login-send">
              <div class="text">
                <img class="text-img" :src="getUrl(item.content)"/>
              </div>
              <img class="head-img" src="@/assets/images/head-sculpture.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="msg-send-box">
        <div class="msg-sel-img">
          <el-upload
            multiple
            :limit="limit"
            action="#"
            :on-exceed="handleExceed"
            name="file"
            :show-file-list="false"
            :file-list="fileList"
            :auto-upload='false'
            :on-change="onChange"
            :class="{hide: this.fileList.length >= this.limit}"
          >
            <img src="@/assets/images/imgupload.png" />
          </el-upload>
        </div>
        <div class="msg-input">
          <el-input type="textarea" v-model="msgStr"></el-input>
        </div>
        <div class="send-btn" @click="sendMsg">发送</div>
      </div>
    </div>
  </div>
  <div class="cop-group1" :class="{'home-header-fixed': show }">
      <div class="group2 flex-row">
        <img
          class="img1"
          referrerpolicy="no-referrer"
          @click="toNewPage(0)"
          src="@/assets/main/iconcopy.png"
        />
        <span
          class="cop-info1"
          :class="type == 0 ? 'info-sel' : ''"
          @click="toNewPage(0)"
          >主页</span
        >
        <div id="sel-menu">
          <span
            class="cop-info2"
            :class="type == 1 ? 'info-sel' : ''"
            @click="toNewPage(1)"
            >产品介绍</span
          >
          <div class="nav-box4 flex-col">
            <div class="nav-group1">
              <div
                class="nav-bd3 flex-row justify-between"
                @click="toProPage(10)"
              >
                <span class="nav-info3">松云ERP</span>
                <div class="nav-mod4 flex-col"></div>
              </div>
              <div
                class="nav-bd3a flex-row justify-between"
                @click="toProPage(13)"
              >
                <span class="nav-word6">松云WMS</span>
                <div class="nav-mod4a flex-col"></div>
              </div>
              <div
                class="nav-bd3a flex-row justify-between"
                @click="toProPage(11)"
              >
                <span class="nav-word7">松云CRM</span>
                <div class="nav-mod4b flex-col"></div>
              </div>
              <div
                class="nav-bd3a flex-row justify-between"
                @click="toProPage(14)"
              >
                <span class="nav-word8">松云TMS</span>
                <div class="nav-mod4c flex-col"></div>
              </div>
              <div
                class="nav-bd3a flex-row justify-between"
                @click="toProPage(12)"
              >
                <span class="nav-txt2">松云OMS</span>
                <div class="nav-mod4d flex-col"></div>
              </div>
              <div
                class="nav-bd3a flex-row justify-between"
                @click="toProPage(15)"
              >
                <span class="nav-info4">松云SCM</span>
                <div class="nav-mod4e flex-col"></div>
              </div>
            </div>
          </div>
        </div>
        <span
          class="cop-info3"
          :class="type == 2 ? 'info-sel' : ''"
          @click="toNewPage(2)"
          >服务体系</span
        >
        <span
          class="cop-word1"
          :class="type == 3 ? 'info-sel' : ''"
          @click="toNewPage(3)"
          >典型案例</span
        >
        <span
          class="cop-info4"
          :class="type == 4 ? 'info-sel' : ''"
          @click="toNewPage(4)"
          >关于松云</span
        >
        <!-- <button class="cop-wrap1 flex-col" @click="toNewPage(5)">
        <span class="txt1">预约注册</span>
      </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import { login,getUserInfo,getMsgList,uploadImg } from "@/api/msg.js"
import { getLogin,setLogin,isLogin } from '@/utils/songyun'
export default {
  props: {
    // 头部的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false, //头部fixed定位判断，动态绑定 class= " home-header-fixed "
      constants: {},
      showMsg: false,
      msgStr: "",
      token: "",
      socket: null,
      userInfo: null,
      msgList: [],
      maanger_thumb_avatar: "",
      manager_id: "",
      selfId: "",
      manager_qrcode: "",
      hasMsg: false,
      scrollIntoIndex: "",

      limit: 1,
      fileSize: 5,
      fileType:  ["png", "jpg", "jpeg"],
      fileList: [],
      uploadImgUrl: "/sys/upload?token="+getLogin(), // 上传的图片服务器地址
    };
  },
  created() {
    if (isLogin()) {
      this.token = getLogin();
      // this.getUserInfo();
      // this.login();
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.showSearch);
  },
  methods: {
    onClick_1() {
      alert(1);
    },
    onClick_2() {
      alert(1);
    },
    onClick_3() {
      alert(1);
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        // case 1: // 产品介绍
        //   url = "/product/erp";
        //   break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    toProPage(index) {
      // 跳转产品介绍页面
      let url = "";
      // index的类型 10-ERP 11-CRM 12-MALL-熊猫游轮 13-MALL-吉利云商城
      // 14-WMS 15-TMS-黄埔文冲 16-TMS-白云机场 17-SCM
      switch (index) {
        case 10: //ERP
          url = "/product/erp";
          break;
        case 11: // CRM
          url = "/product/crm";
          break;
        case 12: // MALL-熊猫游轮
          url = "/product/mall";
          break;
        // case 13: // MALL-吉利云商城
        //   url = "/product/mall";
        //   break;
        case 13: // WMS
          url = "/product/wms";
          break;
        case 14: //TMS-黄埔文冲
          url = "/product/tms";
          break;
        // case 16: //MS-白云机场
        //   url = "/product/tms";
        //   break;
        case 15: //SCM
          url = "/product/scm";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    topFunction() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    // 文件个数超出
    handleExceed() {
      this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    onChange(file,fileList){
      //这里我需要上传mp3文件，所以做了限制
      //将选取的文件拿到，这里需要根据自己业务来写
      let that = this;
      const nameList = file.name.split(".");
      const file_type = nameList[nameList.length - 1];
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        const index = this.result.indexOf("base64,") + 7;
        const file_b64_string = this.result.slice(index);
        that.uploadImg(file_b64_string,file_type)
      }
    },
    uploadImg(file_b64_string,file_type) {
      let data = {
        app_label: "system",
        model_name: "message",
        column_name: "content",
        file_b64_string: file_b64_string,
        file_type:file_type,
      }
      uploadImg(data,getLogin()).then( (res) => {
        let data = {
          to: this.manager_id,
          msgtype:"image",
          image:{
            content:res.data.file_url
          }
        }
        this.socket.send(JSON.stringify(data));
        let data1 = {
          to: this.manager_id,
          msgtype:"image",
          content:res.data.file_url,
        }
        this.msgList.push(data1);
        this.fileList = [];
        this.setMsgInfoHeight(1);
      })
    },
    //头部fixed定位
    showSearch() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        // 当页面滚动到高度300px处，动态绑定class 来设置头部固定定位
        this.show = true;
      } else {
        this.show = false;
      }
    },
    // 选择聊天
    showSendMsg() {
      if (this.token == "") {
        //去登录
        this.login();
      } else {
        this.showMsg = true;
        this.hasMsg = false;
        if (!this.socket) {
          this.createSocket();
        }
      }
    },
    getUrl(url) {
      return url + "&token=" + getLogin();
    },
    login() {
      login().then((res) => {
        this.token = res.data.token;
        setLogin(this.token);
        this.createSocket();
        this.showMsg = true;
        this.hasMsg = false;
      });
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
        this.createSocket();
      });
    },
    getMsgList() {
      let data = {
        customer_id: this.manager_id,
        max_id: "",
      };
      getMsgList(data).then((res) => {
        for (let i = res.data.messages.length - 1; i >= 0; i--) {
          const item = res.data.messages[i];
          this.msgList.unshift(item);
        }
      });
    },
    createSocket() {
      if ("WebSocket" in window) {
        let url = `wss://plat.syinov.com:8001/ws/customer?token=${this.token}`;
        console.log(url);
        this.socket = new WebSocket(url);
        this.socket.onopen = function () {
          console.log("建立连接");
          // Web Socket 已连接上，使用 send() 方法发送数据
        };
        let that = this;
        this.socket.onmessage = function (evt) {
          var received_msg = JSON.parse(evt.data);
          if (received_msg.msgtype == "login") {
            console.log(received_msg);
            that.maanger_thumb_avatar = received_msg.login.maanger_thumb_avatar;
            that.manager_id = received_msg.login.manager_id;
            that.selfId = received_msg.to;
            that.manager_qrcode = received_msg.login.manager_qrcode;
            that.getMsgList();
          } else {
            let item = {
              content: received_msg.msgtype=="text" ? received_msg.text.content:received_msg.image.content,
              create_time: "",
              msgid: received_msg.msgid,
              msgtype: received_msg.msgtype,
              sender: received_msg.sender,
              to: received_msg.to,
            }
            that.msgList.push(item);
            that.setMsgInfoHeight(received_msg.msgtype=="text" ? 0: 1);
          }
          if (that.showMsg == false) {
            that.hasMsg = true;
          } else {
            that.hasMsg = false;
          }
        };
        this.socket.onclose = function () {
          that.socket = null;
          that.showMsg = false;
          console.log("关闭链接!!!");
          this.token = "";
          that.login();
        };
      }
    },
    sendMsg() {
      if (this.msgStr != "") {
        let data = {
          to: this.manager_id,
          msgtype: "text",
          text: {
            content: this.msgStr,
          },
        };
        this.socket.send(JSON.stringify(data));
        let data1 = {
          to: this.manager_id,
          msgtype:"text",
          content:this.msgStr,
        }
        this.msgList.push(data1);
        this.msgStr = "";
        this.setMsgInfoHeight();
      }
    },
    setMsgInfoHeight(type=0) {
      this.$nextTick(() => {
        if (type ==0) {
           let hegihtStr =window.getComputedStyle(this.$refs.msgConnet).height;
          hegihtStr = hegihtStr.slice(0,hegihtStr.length-2);
          hegihtStr = hegihtStr * 1;
          this.$refs.msgInfo.scrollTo(0,hegihtStr);
        } else {
          setTimeout(()=>{
            let hegihtStr =window.getComputedStyle(this.$refs.msgConnet).height;
            hegihtStr = hegihtStr.slice(0,hegihtStr.length-2);
            hegihtStr = hegihtStr * 1;
            this.$refs.msgInfo.scrollTo(0,hegihtStr);
          },200);
        }
        
      });
    },
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss" >
.cop-group1 {
  display: none;
  z-index: 999;
  position: fixed;
  top: 0px;
  // left: 0px;
  z-index: 300;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.04);
  width: 1920px;
  margin: 0 auto;
}
.home-header-fixed {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}
.cop-info1 {
  width: 43px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  // color: rgba(255, 255, 255, 0.88);
  color: #333333;
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 301px;
}

.cop-info1:hover,
.cop-info2:hover,
.cop-info3:hover,
.cop-info4:hover,
.cop-word1:hover {
  color: rgba(37, 184, 47, 1);
}

.cop-info2 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  // color: rgba(255, 255, 255, 0.88);
  color: #333333;
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.cop-info3 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  // color: rgba(255, 255, 255, 0.88);
  color: #333333;
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.cop-word1 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  // color: rgba(255, 255, 255, 0.88);
  color: #333333;
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.cop-info4 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  // color: rgba(255, 255, 255, 0.88);
  color: #333333;
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}
</style>
<style  rel="stylesheet/scss" lang="scss" >
.nav-box4, .nav-group1 {
  display: none;
}
// .nav-box4 {
//   display: block;
//   z-index: 84;
//   height: 350px;
//   background: url(../assets/images/back/navblock.png) -10px -3px no-repeat;
//   background-size: 296px 369px;
//   width: 276px;
//   position: absolute;
//   left: 629px;
//   // top: 97px;
// }
// .nav-box4 .nav-group1 {
//   // display: block;
//   display: flex;
//   width: 156px;
//   height: 260px;
//   flex-direction: column;
//   justify-content: space-between;
//   margin: 50px 0 0 60px;
// }
#sel-menu:hover .nav-box4 {
  display: block;
  z-index: 84;
  height: 382px;
  background: url(../assets/images/back/navblockblackco.png) -10px 29px no-repeat;
  background-size: 296px 369px;
  width: 245px;
  position: absolute;
  left: 629px;
  padding-top: 32px;
  // top: 97px;
}

#sel-menu:hover .nav-box4 .nav-group1 {
  // display: block;
  display: flex;
  width: 156px;
  height: 260px;
  flex-direction: column;
  justify-content: space-between;
  margin: 50px 0 0 60px;
}

.nav-bd3 {
  // width: 109px;
  height: 25px;
  cursor: pointer;
}
.nav-bd3:hover .nav-info3{
  color: rgba(36, 184, 47, 1);
  font-family: AlibabaPuHuiTiM;
}
.nav-info3 {
  width: 77px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
}

.nav-bd3:hover .nav-mod4 {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 43px;
}

.nav-bd3a {
  // width: 109px;
  height: 25px;
  margin-top: 22px;
  cursor: pointer;
}
.nav-bd3a:hover .nav-word6,.nav-bd3a:hover .nav-word7, 
.nav-bd3a:hover .nav-word8, .nav-bd3a:hover .nav-txt2, .nav-bd3a:hover .nav-info4{
  color: rgba(36, 184, 47, 1);
  font-family: AlibabaPuHuiTiM;
}
.nav-bd3a:hover .nav-mod4a {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 34px;
}
.nav-bd3a:hover .nav-mod4b {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 39px;
}
.nav-bd3a:hover .nav-mod4c {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 41px;
}
.nav-bd3a:hover .nav-mod4d {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 41px;
}
.nav-bd3a:hover .nav-mod4e {
  width: 16px;
  // height: 4px;
  background: url(../assets/images/procedure/halfarrow.png) -1px -1px
    no-repeat;
  background-size: 18px 6px;
  margin-top: 9px;
  margin-right: 40px;
}

.nav-word6 {
  width: 86px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
}

.nav-word7 {
  width: 81px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  // margin-top: 22px;
}

.nav-word8 {
  width: 79px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  // margin-top: 22px;
}

.nav-txt2 {
  width: 79px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  // margin-top: 22px;
}

.nav-info4 {
  width: 80px;
  height: 25px;
  display: inline-block;
  // color: rgba(102, 102, 102, 1);
  color: rgba(255, 255, 255 , 0.8);
  font-size: 18px;
  letter-spacing: 1.7142857313156128px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  // margin-top: 22px;
}
</style>
<style rel="stylesheet/scss" lang="scss">
.group1 {
  z-index: 99;
  height: 100px;
  background: url(../assets/main/headback.png) 100% no-repeat;
  width: 1920px;
}

.group2 {
  width: 1775px;
  height: 42px;
  margin: 29px 0 0 65px;
}

.img1 {
  width: 180px;
  height: 38px;
  margin-top: 2px;
  cursor: pointer;
}

.info1 {
  width: 43px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 301px;
}

.info1:hover,
.info2:hover,
.info3:hover,
.info4:hover,
.word1:hover {
  color: rgba(37, 184, 47, 1);
}

.info2 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.el-font {
  font-size: 21px;
  font-family: AlibabaPuHuiTiR;

  overflow-wrap: break-word;
  letter-spacing: 0.41999998688697815px;
  white-space: nowrap;
  line-height: 29px;
}

.ul-menu {
  display: none;
  left: 690px;
  top: 71px;
  position: absolute;
  z-index: 2023;
  transform-origin: center top 0px;
  padding: 10px 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

#sel-menu:hover .ul-menu {
  display: block;
  left: 690px;
  top: 71px;
  position: absolute;
  z-index: 2023;
  transform-origin: center top 0px;
  padding: 10px 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}

.li-menu {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  text-align: left;
  color: #606266;
  cursor: pointer;
  outline: 0;
}

.info3 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.word1 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.info4 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  cursor: pointer;
  margin: 7px 0 0 104px;
}

.info-sel {
  // color: rgba(255, 255, 255, 1);
  color: rgba(37, 184, 47, 1);
  font-family: AlibabaPuHuiTiB;
}

.wrap1 {
  height: 42px;
  border-radius: 21px;
  border: 2px solid rgba(37, 184, 47, 1);
  margin-left: 346px;
  width: 149px;
  cursor: pointer;
}

.txt1 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.94);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 32px;
}

.outer5 {
  // height: 328px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  margin-left: 152px;
  width: 105px;
  position: fixed;
  bottom: 45px !important;
  right: 24px;
  z-index: 999;
}

.group5 {
  width: 105px;
  // height: 327px;
}

.outer6 {
  width: 36px;
  height: 36px;
  background: url(../assets/main/floatone.png) 0px 0px no-repeat;
  margin-left: 36px;
}

.outer6-1 {
  width: 36px;
  height: 36px;
  background: url(../assets/main/floatone.png) 0px 0px no-repeat;
  margin-left: 12px;
}

.outer8:hover .outer6-1 {
  width: 36px;
  height: 36px;
  background: url(../assets/main/floatonehover.png) 0px 0px no-repeat;
  margin-left: 12px;
}

.info11 {
  width: 57px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 14px;
  letter-spacing: 0.2800000011920929px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
  margin: 7px 0 0 24px;
}

.outer7 {
  width: 35px;
  height: 35px;
  background: url(../assets/main/floattwo.png) 0px 0px no-repeat;
  margin-left: 12px;
}

.outer8:hover .outer7 {
  width: 35px;
  height: 35px;
  background: url(../assets/main/floattwohover.png) 0px 0px no-repeat;
  margin-left: 12px;
}

.txt4 {
  width: 57px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 14px;
  letter-spacing: 0.2800000011920929px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
  margin: 8px 0 0 24px;
}

.outer8 {
  height: 84px;
  background-color: rgba(255, 255, 255, 1);
  width: 105px;
  cursor: pointer;
}

.outer8:hover {
  height: 84px;
  background-color: rgba(36, 184, 47, 1);
  width: 105px;
}

.mod6 {
  width: 57px;
  height: 65px;
  margin: 11px 0 0 24px;
}

.layer3 {
  width: 38px;
  height: 38px;
  background: url(../assets/main/floatthird.png) 0px -1px no-repeat;
  margin-left: 11px;
}

.outer8:hover .layer3 {
  width: 38px;
  height: 38px;
  background: url(../assets/main/floatthirdhover.png) 0px 0px no-repeat;
  margin-left: 11px;
}

.layer3-1 {
  width: 38px;
  height: 38px;
  background: url(../assets/main/floatfour.png) 0px 0px no-repeat;
  margin-left: 10px;
}

.outer8:hover .layer3-1 {
  width: 38px;
  height: 38px;
  background: url(../assets/main/floatfourhover.png) 0px 0px no-repeat;
  margin-left: 10px;
}

.word7 {
  width: 57px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 14px;
  letter-spacing: 0.2800000011920929px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
  margin-top: 7px;
}

.outer8:hover .word7 {
  width: 57px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  letter-spacing: 0.2800000011920929px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
  margin-top: 7px;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.msg-send {
  width: 408px;
  height: 475px;
  position: fixed;
  right: 172px;
  bottom: 10px;
  background-color: rgba(245, 245, 245, 1);
  z-index: 9999;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);

  .msg-head {
    width: 408px;
    height: 57px;
    background-color: rgba(36, 184, 47, 1);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }

  .msg-info {
    width: 408px;
    height: 255px;
    overflow-y: scroll;

    .login-msg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 388px;
      height: 30px;
      color: rgba(153, 153, 153, 1);
      margin: 10px 0px;
    }

    .login-receive {
      display: flex;
      align-items: flex-start;
      margin: 0px 10px 10px 10px;
      .head-img {
        width: 40px;
        height: 40px;
      }

      .text {
        max-width: 270px;
        margin-left: 8px;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        padding: 8px;
        .text-img {
          max-width: 254px;
          max-height: 254px;
        }
      }
    }

    .login-send {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin: 0px 10px 10px 10px;
      .text {
        margin-right: 8px;
        max-width: 270px;
        background: #24b82f;
        padding: 8px;
        color: #fff;
        .text-img {
          max-width: 254px;
          max-height: 254px;
        }
      }
    }
  }

  .msg-send-box {
    width: 408px;
    height: 163px;
    background-color: #fff;

    .msg-sel-img {
      display: flex;
      padding-left: 20px;
      padding-top: 6px;
      img {
        cursor: pointer;
        width: 28px;
        height: 28px;
      }
    }

    .msg-input {
      padding: 6px 10px 0px 10px;
    }

    .send-btn {
      width: 53px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #fff;
      background-color: rgba(36, 184, 47, 1);

      margin: 20px 0px 0px 327px;
      cursor: pointer;
    }
  }
}

.has-msg {
  position: relative;
  animation: mymove 2s infinite;
  -webkit-animation: twinkling 1s infinite ease-in-out;
}

// .animated{
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both
// }

@-webkit-keyframes twinkling {
  0% {
    top: 0px;
    opacity: 1;
  }

  50% {
    top: 5px;
    opacity: 0.5;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes twinkling {
  0% {
    top: 0px;
    opacity: 1;
  }

  50% {
    top: 5px;
    opacity: 0.5;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}
</style>