import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import store from './store'

import plugins from './plugins' // plugins

// 字典标签组件
import DictTag from '@/components/DictTag'
// 字典数据组件
import DictData from '@/components/DictData'

// 分页组件
import headInfo from "@/components/headInfo";
import footInfo from "@/components/footInfo";
import secFoot from "@/components/secFoot";

// import directive from './directive' // directive

Vue.config.productionTip = false

Vue.component('headInfo', headInfo)

Vue.component('footInfo', footInfo)

Vue.component('secFoot', secFoot)

Vue.component('DictTag', DictTag)

Vue.use(ElementUI)
Vue.use(plugins)
// Vue.use(directive)

DictData.install()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if(to.meta.content){
   let head = document.getElementsByTagName('head');
   let meta = document.createElement('meta');
   document.querySelector('meta[name="keywords"]').setAttribute('content',to.meta.content.keywords)
   document.querySelector('meta[name="description"]').setAttribute('content',to.meta.content.description)
   meta.content = to.meta.content;
   head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
   document.title = to.meta.title;
  }
  next()
 });
