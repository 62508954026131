<template>
  <div class="page flex-col">
    <div class="block1 flex-col">
      <div class="layer1 flex-col">
        <div class="mod1 flex-col">
          <headInfo :type="type"></headInfo>
          
          <span class="info5"
            >松云系统&nbsp;&nbsp;&nbsp;全方位打造数字化企业</span
          >
          <span class="info6"
            >一站式数据集成支撑企业战略&nbsp;&nbsp;&nbsp;执行全领域办公协同助力企业数字化转型升级</span
          >
          <!-- <div class="group3 flex-col" @click="toNewPage(5)">
            <span class="info7" @click="toNewPage(5)">开启旅程</span>
          </div> -->
        </div>
        <div class="mod2 flex-col"></div>
        <!-- <div class="mod3 flex-col"></div> -->
        <!-- <router-link to="/test"> -->
          <!-- <span class="info7">开启旅程</span> -->
        <!-- </router-link> -->
        <!-- <div class="mod4 flex-col"></div> -->
      </div>
      <span class="word2">优势</span>
      <span class="info8"
        >解决企业难题，助力数字化转型，让协同工作效率流转更高效</span
      >
      <div class="layer2 flex-row">
        <div class="outer1 flex-col">
          <div class="section1 flex-col"></div>
          <span class="word3">生态</span>
          <span class="txt2">打造系统生态，全流程闭环，助力企业生命力延续</span>
        </div>
        <div class="outer2 flex-col">
          <div class="main1 flex-col"></div>
          <span class="word4">定制化</span>
          <span class="word5">根据特有需求定制化设计，六大团队及时支撑</span>
        </div>
        <div class="outer3 flex-col">
          <div class="group4 flex-col"></div>
          <span class="txt3">大数据</span>
          <span class="info9">采用先进算法，集成3D效果，获取信息清晰及时</span>
        </div>
        <div class="outer4 flex-col">
          <div class="mod5 flex-col"></div>
          <span class="info10">轻量化</span>
          <span class="word6">云端部署/私有化部署任意选择，系统轻量化</span>
        </div>
        <!-- <div class="outer5 flex-col">
          <div class="group5 flex-col">
            <div class="outer8 flex-col">
              <div class="mod6 flex-col justify-between">
                <div class="outer6-1 flex-col"></div>
                <span class="word7">在线咨询</span>
              </div>
            </div>
            <div class="outer8 flex-col">
              <div class="mod6 flex-col justify-between">
                <div class="outer7 flex-col"></div>
                <span class="word7">电话咨询</span>
              </div>
            </div>
            <div class="outer8 flex-col">
              <div class="mod6 flex-col justify-between">
                <div class="layer3 flex-col"></div>
                <span class="word7">免费试用</span>
              </div>
            </div>
            <div class="outer8 flex-col">
              <div class="mod6 flex-col justify-between">
                <div class="layer3-1 flex-col"></div>
                <span class="word7">回到顶部</span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <span class="word8">场景解决方案</span>
      <span class="word9"
        >洞察需求，全场景、全规模、全方位覆盖，以无人化、自动化为创新途径逐步朝向智能化管理</span
      >
      <div class="layer4 flex-row">
        <div class="layer5 flex-col">
          <div class="outer9 flex-col justify-between">
            <span class="txt5">数据可视化，信息一键触达</span>
            <span class="word10"
              >销售人员、操作人员绩效展示，以及库位可视化、库位热度可视化，精确到每一个库位，所有信息一目了然</span
            >
          </div>
        </div>
        <div class="layer6 flex-col">
          <div class="mod7 flex-col justify-between">
            <span class="txt6">无纸化作业，提升效率</span>
            <span class="info13"
              >松云WMS实现全流程无纸化作业，利用条码技术与信息处理技术相结合快速进行出入库、批次以及盘点等多项作业，以正确、低成本的方式为客户提供服务。</span
            >
          </div>
        </div>
        <div class="layer7 flex-col">
          <div class="box1 flex-col justify-between">
            <span class="txt7">多种策略规划提供省心服务</span>
            <span class="word11"
              >支持收货策略、上架策略、拣货策略等多种仓储作业策略，降低产品实施难度。通过智能化路线规划，策略化运力配置等，实现全链路协同管理，大大降低物流运输成本。</span
            >
          </div>
        </div>
        <div class="layer8 flex-col">
          <div class="box2 flex-col justify-between">
            <span class="txt8">执行便捷，应用简易</span>
            <span class="word12"
              >松云ERP在所有的关键点上均进行了人性化规定，确保一个软件使全企业每一个职工在工作上每一个环节维持深层次连动，解决公司在企业生产管理上的隔断难点。</span
            >
          </div>
        </div>
        <div class="layer9 flex-col">
          <div class="bd1 flex-col justify-between">
            <span class="word13">数百种营销策略支持运营放肆“玩”</span>
            <span class="txt9"
              >支持前端买一赠一，买A赠B，回购赠，按购买次数、会员等级赠，上百种赠品策略满足营销需求，实现组合套装、爆款、直播等多样性营销落地，平衡经营成本与用户体验</span
            >
          </div>
        </div>
        <div class="layer10 flex-col">
          <div class="mod8 flex-col justify-between">
            <span class="word14">有效批次管理，临期货品优先提醒</span>
            <span class="txt10"
              >统一货品指定不同批次发货，优先选择临期批次出库，确保批次先进先出；&nbsp;支持提醒临期货品优先发货和到期货品自动拦截；支持委外仓批次有效期同步回传</span
            >
          </div>
        </div>
      </div>
      <div class="layer11 flex-col">
        <div class="wrap2 flex-row"><span class="txt11">六大平台</span></div>
        <div class="wrap3 flex-row">
          <span class="info14">多平台协同，全方位覆盖，全流程打通</span>
        </div>
        <div class="wrap4 flex-row justify-between">
          <div @click="onClick_2()" class="pic1" />
          <div @click="onClick_2()" class="img2" />
        </div>
        <div class="wrap5 flex-col"><div class="section2 flex-col"></div></div>
      </div>
      <div class="layer12 flex-row justify-between">
        <div class="block2 flex-col">
          <div class="mod9 flex-col">
            <span class="word15">服务用户</span>
            <div class="imgs1 flex-col"></div>
            <span class="word16">100+</span>
          </div>
        </div>
        <div class="block3 flex-col">
          <div class="group6 flex-col justify-between">
            <span class="word17">工作效率提升</span>
            <div class="imgs2 flex-col"></div>
            <span class="info15">60%</span>
          </div>
        </div>
        <div class="block4 flex-col">
          <div class="mod10 flex-col">
            <span class="txt12">数据可靠性</span>
            <div class="imgs3 flex-col"></div>
            <span class="word18">99.9%</span>
          </div>
        </div>
        <!-- <div class="block5 flex-col">
          <div class="box3 flex-col justify-between">
            <span class="word19">订单处理/秒</span>
            <div class="imgs4 flex-col"></div>
            <span class="word20">100,000+</span>
          </div>
        </div> -->
        <div class="block5 flex-col">
          <div class="box3 flex-col">
            <span class="word19">订单处理/秒</span>
            <div class="imgs4 flex-col"></div>
            <span class="word20">100,000+</span>
          </div>
        </div>
      </div>
      <div class="layer13 flex-col">
        <!-- <div class="bd2 flex-row"><span class="word21">合作伙伴</span></div>
        <div class="bd3 flex-row justify-between">
          <div class="section3 flex-col"><div class="box4 flex-col"></div></div>
          <div class="section4 flex-col"><div class="box5 flex-col"></div></div>
          <div class="section5 flex-col">
            <div class="block6 flex-col"></div>
          </div>
          <div class="section6 flex-col">
            <div class="group7 flex-col"></div>
          </div>
          <div class="section7 flex-col">
            <div class="section8 flex-col"></div>
          </div>
        </div>
        <div class="bd4 flex-row justify-between">
          <div class="section9 flex-col"><div class="bd5 flex-col"></div></div>
          <div class="section10 flex-col">
            <div class="outer10 flex-col"></div>
          </div>
          <div class="section11 flex-col">
            <div class="mod11 flex-col"></div>
          </div>
          <div class="section12 flex-col">
            <img
              class="pic2"
              referrerpolicy="no-referrer"
              src="@/assets/images/partner/yuntu.png"
            />
          </div>
          <div class="section13 flex-col">
            <div class="outer11 flex-col"></div>
          </div>
        </div>
        <div class="bd6 flex-row justify-between">
          <div class="mod12 flex-col">
            <div class="section14 flex-col"></div>
          </div>
          <div class="mod13 flex-col"><div class="main2 flex-col"></div></div>
          <div class="mod14 flex-col">
            <div class="section15 flex-col"></div>
          </div>
          <div class="mod15 flex-col"><div class="mod16 flex-col"></div></div>
          <div class="mod17 flex-col"><div class="block7 flex-col"></div></div>
          <div class="mod18 flex-col"><div class="bd7 flex-col"></div></div>
        </div> -->
        <!-- <img
          class="pic3"
          referrerpolicy="no-referrer"
          src="@/assets/images/partner/backpart.png"
        /> -->
      </div>
      <span class="info16">行业解决方案</span>
      <div class="tab-ident">
        <!-- <div class="img3" @click="onClickLoop(-1)" /> -->
        <el-tabs v-model="activeName">
          <el-tab-pane label="松云WMS" name="first">
            <div class="layer14 flex-col">
              <div class="bd8 flex-row justify-between">
                <div class="group8 flex-col">
                  
                  <div class="pic4 flex-col"></div>
                </div>
                <div class="group9 flex-col">
                  <span class="word22">智能仓储解决方案</span>
                  <span class="word23"
                    >由传统“人到货”变为“货到人”，改善作业效率、降低差错率；
通过对传统仓库进行智能化改造规划，引入无人立库和AGV设备，配合自动扫码称重设备DWS以及自动分拣设备，提升出库分拣效率。 </span
                  >
                  <!-- <span class="info17"
                    >构建统一销售商城平台，方便经销商、直营店、服务门店挂售商品和服务，方便汽车品牌下的用户在平台上根据车型等数据购买匹配的汽车官方配件及检测、保养等服务</span
                  > -->
                  <button class="section16 flex-col" @click="jumpPage('instance','header-ident','second')">
                    <span class="info18">查看详情</span>
                  </button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云SCM" name="second">
            <div class="sol-outer13 flex-col">
              <div class="sol-bd14 flex-row justify-between">
                <div class="sol-box11 flex-col">
                  <div class="sol-img6 flex-col"></div>
                </div>
                <div class="sol-box12 flex-col">
                  <span class="sol-word20">全渠道营销解决方案</span>
                  <div class="sol-TextGroup12 flex-col justify-between">
                    <span class="sol-txt14"
                      >满足线上、线下全渠道营销的新零售场景，从APP商城、门店、社交媒体发起订单，流转至仓储管理平台WMS，进行多仓商品调度，智能分派物流配送，实现全场景费用核算，实现订单智能高效履约。</span
                    >
                    <!-- <span class="sol-info8"
                      >采用大型立库，货位翻倍，可存储量翻倍，并配有两台一体机，仓库操作员无需即可完成各种任务，节省人力</span
                    > -->
                  </div>
                  <div class="sol-bd15 flex-col" @click="jumpPage('instance','header-ident','first')">
                    <span class="sol-txt15">查看详情</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="松云ERP" name="third">
            <div class="sov-outer24 flex-col">
              <div class="sov-block4 flex-row justify-between">
                <div class="sov-layer10 flex-col">
                  <div class="sov-img2" />
                </div>
                <div class="sov-layer11 flex-col">
                  <span class="sov-txt17">综合供应链解决方案</span>
                  <span class="sov-paragraph1"
                    >搭建由OMS/WMS/TMS/BMS核心系统组成的综合供应链管理平台，为物流货主、承运商、货站、保税仓等客户提供完整管理平台。一站式实现供应链订单审核、状态跟踪、物流运力分派、月台预约、在途管理、路径规划、智能仓储、Milk Run循环取货。</span
                  >
                  <button class="sov-mod11 flex-col" @click="jumpPage('instance','header-ident','six')">
                    <span class="sov-txt18">查看详情</span>
                  </button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 在这 -->
      <div class="tab-location flex-row">
      <!-- <div class="img3" @click="onClickLoop(-1)" /> -->
      <el-tabs v-model="activeCard">
        <el-tab-pane label="ONE" name="one">
          <div class="plat-one flex-row">
            <div class="block8 flex-col">
              <div class="mod19 flex-col">
                <span class="word36">松云WMS</span>
                <span class="txt18"
                  >信息化、智能化、无纸化高效追溯管理，实现信息透明化、数据实时准确</span
                >
                <span class="info26"
                  >全渠道管理，多层级多仓多货主多模式，根据不同业务提供差异化的行业解决方案</span
                >
                <span class="info27"
                  >强大系统扩展性，使WMS、ERP、TMS等多个信息系统实现无缝对接，满足仓配一体化管理</span
                >
              </div>
            </div>
            <div class="block9 flex-col">
              <div class="outer14 flex-col">
                <span class="word36">松云ERP</span>
                <span class="info28"
                  >为传统行业赋能，目前在服装产链、元件生产类行业有丰富的经验积累</span
                >
                <span class="word37"
                  >松云ERP采用严谨的管理理念，为企业各部门打通信息沟通壁垒，保障各运营部门信息传递、沟通流畅，为财务、供应链提供坚实的软件基础，为管理者运筹帷幄提供实时、生动、精准的智能大数据分析</span
                >
              </div>
            </div>
            <div class="block10 flex-col">
              <div class="main3 flex-col">
                <span class="word36">松云CRM</span>
                <span class="word39"
                  >运用AI推荐算法，从1.2亿企业中找出相似企业生成合作客户画像，自动扩展潜在客户，无需企业调研搜寻客户线索，精准获客效率提升几十倍</span
                >
                <span class="word40"
                  >线索到合同签订全流程可视化透明跟进分析客户成交成功率大大增加合作概率</span
                >
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="TWO" name="two">
          <div class="pla-outer24 flex-row">
            <div
              class="pla-box20 flex-col"
              :style="{ background: item.lanhuBg0 }"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <div class="pla-main15 flex-col">
                <span class="pla-info13" v-html="item.lanhutext0"></span>
                <div
                  v-if="item.slot4 === 4"
                  class="pla-TextGroup10 flex-col justify-between"
                >
                  <span class="pla-word42"
                    >强大的底层架构、灵活的商业模式、全渠道营销场景、移动应用生态</span
                  >
                  <span class="pla-info14"
                    >全渠道客户管理、奖励身份同步、会员客户分析、个性化店面装修</span
                  >
                  <span class="pla-infoBox1"
                    >云商城线上部署，多平台协同开发<br />新零售线下部署，智能设备一体化</span
                  >
                </div>
                <span v-if="item.slot1 === 1" class="pla-word40"
                  >从生产到运输，从供应商到顾客每一个环节，为业务部门提供决策依据，参与助力企业协同合作、需求梳理、高效工作、信息流转等所有难题</span
                >
                <span v-if="item.slot2 === 2" class="pla-word41"
                  >企业通过改善供应链关系，整合信息流、物流、资金流，有效助力企业更具独特竞争优势</span
                >
                <div
                  v-if="item.slot3 === 3"
                  class="pla-TextGroup11 flex-col justify-between"
                >
                  <span class="pla-word37"
                    >运用先进算法，解决企业运输线路、多订单追踪管理、云端智能调度、账单管理、可视化运输过程等场景中的任何问题，减少企业压力，增加行业竞争力</span
                  >
                  <span class="pla-word38"
                    >灵活丰富的集成接口，可无缝快速对接多种系统</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
      <div class="layer15 flex-col">
        <div class="layer16 flex-col justify-between">
          <span class="info19">助力仓储物流数智化</span>
          <!-- <button class="wrap6 flex-col" @click="toNewPage(5)">
            <span class="txt13">预约注册</span>
          </button> -->
        </div>
      </div>
      <footInfo />
    </div>

    <div class="block11 flex-col"></div>
    <div class="img3" @click="onClickLoop(-1)" />
    <div class="pic5" @click="onClickLoop(1)" />
    <div class="block12 flex-col">
      <img
        class="img4"
        referrerpolicy="no-referrer"
        src="@/assets/images/partner/leftBlock.png"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 0,
      activeName: "first",
      val: 0,
      activeCard: "one",
      cardVal: true,
      loopData0: [
        {
          lanhuBg0:
            "url("+require('../assets/main/earthone.png')+") 100% no-repeat",
          lanhutext0: "松云TMS",
          slot3: 3,
        },
        {
          lanhuBg0:
            "url("+require('../assets/main/earthtwo.png')+") 100% no-repeat",
          lanhutext0: "松云SCM",
          slot1: 1,
          slot2: 2,
        },
        {
          lanhuBg0:
            "url("+require('../assets/main/earththird.png')+") 100% no-repeat",
          lanhutext0: "松云MALL",
          slot4: 4,
        },
      ],
    };
  },
  methods: {
    onClickLoop(index) {
      this.val = (3 + this.val + index) % 3;
      switch (this.val) {
        case 0:
          this.activeName = "first";
          break;
        case 1:
          this.activeName = "second";
          break;
        case 2:
          this.activeName = "third";
          break;
      }
    },
    onClick_2() {
      if (this.cardVal) {
        this.cardVal = false;
        this.activeCard = "two";
      } else {
        this.cardVal = true;
        this.activeCard = "one";
      }
    },
    jumpPage(path, id, name) {
      var path=path
      var Id=id;
      var name = name;
      localStorage.setItem('toId',Id);
      this.$router.push({
        name: path,
        params: {
          sel: name
        }
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.tab-location {
  z-index: 2;
  position: absolute;
  left: 200px;
  top: 3329px;
  
}

.tab-location .el-tabs .el-tabs__header {
    display: none;
}
</style>
<style  rel="stylesheet/scss" lang="scss" >
.pla-outer24 {
  z-index: 122;
  width: 1520px;
  height: 551px;
  justify-content: space-between;
  // position: absolute;
  // left: 200px;
  // top: 3329px;
  .pla-box20 {
    height: 551px;
    margin-right: 28px;
    width: 488px;
    .pla-main15 {
      width: 322px;
      height: 322px;
      margin: 96px 0 0 83px;
      .pla-info13 {
        width: 132px;
        height: 38px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 28px;
        letter-spacing: 0.5600000023841858px;
        font-family: AlibabaPuHuiTiM;
        text-align: left;
        white-space: nowrap;
        line-height: 38px;
        margin-left: 89px;
        display: block;
      }
      .pla-TextGroup10 {
        width: 322px;
        height: 240px;
        margin: 36px 0 8px 0;
        .pla-word42 {
          width: 322px;
          height: 64px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .pla-info14 {
          width: 322px;
          height: 64px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 32px;
          margin-top: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .pla-infoBox1 {
          width: 322px;
          height: 64px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 32px;
          white-space: nowrap;
          margin-top: 24px;
          display: block;
        }
      }
      .pla-word40 {
        width: 322px;
        height: 128px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiR;
        text-align: left;
        line-height: 32px;
        margin-top: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .pla-word41 {
        width: 322px;
        height: 96px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        letter-spacing: 1.5px;
        font-family: AlibabaPuHuiTiR;
        text-align: left;
        line-height: 32px;
        margin-top: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      .pla-TextGroup11 {
        width: 322px;
        height: 248px;
        margin-top: 36px;
        .pla-word37 {
          width: 322px;
          height: 160px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .pla-word38 {
          width: 322px;
          height: 64px;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 32px;
          margin-top: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
  .pla-box20:hover {
    background: rgba(255, 255, 255, 1) !important;
    border: 1px solid rgba(245, 245, 245, 1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    // background: url() !important;
    .pla-info13 {
      color: rgba(40, 40, 40, 1);
    }
    .pla-TextGroup11 .pla-word37,
    .pla-TextGroup11 .pla-word38 {
      color: rgba(102, 102, 102, 1);
    }
    .pla-word40,
    .pla-word41 {
      color: rgba(102, 102, 102, 1);
    }
    .pla-TextGroup10 .pla-word42,
    .pla-TextGroup10 .pla-info14,
    .pla-TextGroup10 .pla-infoBox1 {
      color: rgba(102, 102, 102, 1);
    }
  }
}
</style>
<style  rel="stylesheet/scss" lang="scss" >
.tab-ident .el-tabs .el-tabs__header {
  // padding: 0;
  // position: relative;
  // margin: 0 0 15px;
  display: none;
}
.sol-outer13 {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  background-color: rgba(255, 255, 255, 1);
  height: 472px;
  border: 1px solid rgba(245, 245, 245, 1);
  width: 1181px;
  margin: 60px 0 0 370px;
  .sol-bd14 {
    width: 1092px;
    height: 472px;
    .sol-box11 {
      background-color: rgba(245, 245, 245, 1);
      height: 472px;
      width: 576px;
      .sol-img6 {
        width: 100%;
        height: 100%;
        background: url(../assets/main/camera.png)
          100% no-repeat;
        // margin: 127px 0 0 148px;
      }
    }
    .sol-box12 {
      width: 387px;
      height: 336px;
      margin-top: 61px;
      .sol-word20 {
        width: 241px;
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
        display: block;
      }
      .sol-TextGroup12 {
        width: 387px;
        height: 170px;
        margin-top: 28px;
        .sol-txt14 {
          width: 387px;
          height: 146px;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .sol-info8 {
          width: 387px;
          height: 90px;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          text-align: left;
          line-height: 30px;
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
      .sol-bd15 {
        // box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        // background-color: rgba(37, 184, 47, 1);
        border: 1px solid rgba(37, 184, 47, 1);
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        height: 57px;
        margin-top: 48px;
        width: 145px;
        cursor: pointer;
        .sol-txt15 {
          width: 73px;
          height: 25px;
          overflow-wrap: break-word;
          // color: rgba(255, 255, 255, 1);
          color: rgba(37, 184, 47, 1);
          font-size: 18px;
          letter-spacing: 0.36000001430511475px;
          font-family: AlibabaPuHuiTiM;
          text-align: left;
          white-space: nowrap;
          line-height: 25px;
          display: block;
          margin: 16px 0 0 36px;
        }
      }
      .sol-bd15:hover {
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        background-color: rgba(37, 184, 47, 1);
        .sol-txt15 {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
.sov-outer24 {
  height: 472px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 1181px;
  margin: 60px 0 0 370px;
  .sov-block4 {
    width: 1092px;
    height: 472px;
    .sov-layer10 {
      z-index: 232;
      position: relative;
      width: 576px;
      height: 472px;
      overflow: hidden;
      background: url(../assets/images/back/greyblock.png)
        100% no-repeat;
      .sov-img2 {
        z-index: 233;
        // position: absolute;
        // left: 132px;
        // top: 171px;
        width: 100%;
        height: 100%;
        background: url(../assets/main/car.png)
          100% no-repeat;
        // margin: 127px 0 0 148px;
      }
    }
    .sov-layer11 {
      width: 387px;
      height: 336px;
      margin-top: 60px;
      .sov-txt17 {
        width: 217px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: left;
      }
      .sov-paragraph1 {
        width: 387px;
        height: 176px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        line-height: 30px;
        text-align: left;
        margin-top: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sov-mod11 {
        height: 57px;
        border: 1px solid rgba(37, 184, 47, 1);
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        margin-top: 68px;
        width: 145px;
        cursor: pointer;
        .sov-txt18 {
          width: 73px;
          height: 25px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(37, 184, 47, 1);
          font-size: 18px;
          letter-spacing: 0.36000001430511475px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 25px;
          text-align: left;
          margin: 16px 0 0 36px;
        }
      }
      .sov-mod11:hover {
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        background-color: rgba(37, 184, 47, 1);
        .sov-txt18 {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss">
.page {
  position: relative;
  width: 1920px;
  height: 6711px;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  // text-align: center;
  margin: 0 auto;
}

.block1 {
  width: 1920px;
  height: 6711px;
}

.layer1 {
  z-index: 61;
  height: 920px;
  overflow: hidden;
  // background: url(../assets/main/graybig.png)
  //   100% no-repeat;
  width: 1920px;
  position: relative;
}

.mod1 {
  width: 1920px;
  height: 605px;
}

.group1 {
  height: 100px;
  background: url(../assets/images/back/headback.png)
    100% no-repeat;
  width: 1920px;
}

.group2 {
  width: 1775px;
  height: 42px;
  margin: 29px 0 0 65px;
}

.img1 {
  width: 180px;
  height: 38px;
  margin-top: 2px;
}

.info1 {
  width: 43px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiB;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 301px;
}

.info2 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 104px;
}

.info3 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 104px;
}

.word1 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 104px;
}

.info4 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.88);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 104px;
}

.wrap1 {
  height: 42px;
  border-radius: 21px;
  border: 2px solid rgba(37, 184, 47, 1);
  margin-left: 346px;
  width: 149px;
}

.txt1 {
  width: 85px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.94);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 7px 0 0 32px;
}

.info5 {
  width: 775px;
  height: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 72px;
  text-align: center;
  margin: 252px 0 0 573px;
}

.info6 {
  width: 671px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 2px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  margin: 32px 0 0 625px;
}

.group3 {
  width: 231px;
  height: 72px;
  border-radius: 42px;
  border: 2px solid rgba(255, 255, 255, 1);
  margin: 52px 0 0 845px;
}

.mod2 {
  z-index: -1;
  position: absolute;
  left: 1px;
  top: -526px;
  width: 1920px;
  height: 1446px;
  background: url(../assets/main/homepage.png) 0px 527px no-repeat;
}

.mod3 {
  z-index: 64;
  position: absolute;
  left: 0;
  top: 0;
  width: 1920px;
  height: 920px;
}

.info7 {
  z-index: 97;
  position: absolute;
  left: 912px;
  top: 552px;
  width: 97px;
  height: 33px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
}

.mod4 {
  z-index: 62;
  position: absolute;
  left: 0;
  top: 517px;
  width: 1920px;
  height: 403px;
}

.word2 {
  width: 65px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
  margin: 120px 0 0 928px;
}

.info8 {
  width: 898px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 32px;
  text-align: center;
  margin: 24px 0 0 511px;
}

.layer2 {
  width: 1582px;
  height: 376px;
  margin: 77px 0 0 314px;
}

.outer1 {
  width: 245px;
  height: 254px;
  margin-top: 2px;
}

.section1 {
  width: 127px;
  height: 108px;
  background: url(../assets/main/greenone.png)
    0px -1px no-repeat;
  margin-left: 56px;
}

.word3 {
  width: 43px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 45px 0 0 101px;
}

.txt2 {
  width: 245px;
  height: 56px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  letter-spacing: 1.1666666269302368px;
  font-family: AlibabaPuHuiTiR;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer2 {
  width: 245px;
  height: 247px;
  margin: 9px 0 0 115px;
}

.main1 {
  width: 152px;
  height: 107px;
  background: url(../assets/main/greentwo.png) -1px -2px
    no-repeat;
  margin-left: 47px;
}

.word4 {
  width: 64px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 39px 0 0 91px;
}

.word5 {
  width: 245px;
  height: 56px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  letter-spacing: 1.1666666269302368px;
  font-family: AlibabaPuHuiTiR;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer3 {
  width: 245px;
  height: 245px;
  margin: 11px 0 0 115px;
}

.group4 {
  width: 130px;
  height: 93px;
  background: url(../assets/main/greenthird.png)
    0px -1px no-repeat;
  margin-left: 57px;
}

.txt3 {
  width: 64px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: center;
  margin: 51px 0 0 91px;
}

.info9 {
  width: 245px;
  height: 56px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  letter-spacing: 1.1666666269302368px;
  font-family: AlibabaPuHuiTiR;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outer4 {
  width: 245px;
  height: 251px;
  margin: 5px 0 0 115px;
}

.mod5 {
  width: 138px;
  height: 100px;
  background: url(../assets/main/greenfour.png)
    0px -1px no-repeat;
  margin-left: 65px;
}

.info10 {
  width: 64px;
  height: 29px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 21px;
  letter-spacing: 0.41999998688697815px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 29px;
  text-align: left;
  margin: 50px 0 0 101px;
}

.word6 {
  width: 245px;
  height: 56px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  letter-spacing: 1.1666666269302368px;
  font-family: AlibabaPuHuiTiR;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon1 {
  width: 38px;
  height: 38px;
  margin: 15px 0 0 34px;
}

.info12 {
  width: 57px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 14px;
  letter-spacing: 0.2800000011920929px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
  margin: 7px 0 0 24px;
}

.word8 {
  width: 193px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
  margin-left: 864px;
}

.word9 {
  width: 898px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 32px;
  text-align: center;
  margin: 24px 0 0 511px;
}

.layer4 {
  width: 1920px;
  height: 1260px;
  margin-top: 48px;
  flex-wrap: wrap;
}

/* // .mod6-1 {
//   width: 57px;
//   height: 63px;
//   margin: 13px 0 0 24px;
// } */

.layer5 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.layer5:hover {
  // z-index: 80;
  background: url(../assets/main/sceneone.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.layer5:nth-child(2n) {
  margin-right: 0;
}
.layer5:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

.outer9 {
  width: 545px;
  height: 118px;
  margin: 100px 0 0 303px;
}

.txt5 {
  width: 338px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: right;
  margin-left: 206px;
}

.word10 {
  width: 545px;
  height: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: right;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer6 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.layer6:hover {
  // z-index: 80;
  background: url(../assets/main/scenetwo.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.mod7 {
  width: 566px;
  height: 150px;
  margin: 100px 0 0 112px;
}

.txt6 {
  width: 282px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
}

.info13 {
  width: 566px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer7 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.layer7:hover {
  // z-index: 80;
  background: url(../assets/main/scenethird.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.box1 {
  width: 545px;
  height: 150px;
  margin: 97px 0 0 301px;
}

.txt7 {
  width: 338px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: right;
  margin-left: 206px;
}

.word11 {
  width: 545px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: right;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer8 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.layer8:hover {
  // z-index: 80;
  background: url(../assets/main/scenefour.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.box2 {
  width: 566px;
  height: 150px;
  margin: 100px 0 0 112px;
}

.txt8 {
  width: 254px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
}

.word12 {
  width: 566px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer9 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.layer9:hover {
  // z-index: 80;
  background: url(../assets/main/scenefive.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 959px;
}

.bd1 {
  width: 545px;
  height: 150px;
  margin: 97px 0 0 301px;
}

.word13 {
  width: 450px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: right;
  margin-left: 94px;
}

.txt9 {
  width: 545px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: right;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer10 {
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.layer10:hover {
  // z-index: 80;
  background: url(../assets/main/scenesix.png)
    100% no-repeat;
  height: 420px;
  border: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 961px;
}

.mod8 {
  width: 566px;
  height: 150px;
  margin: 100px 0 0 112px;
}

.word14 {
  width: 422px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
}

.txt10 {
  width: 566px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer11 {
  // z-index: 57;
  height: 683px;
  overflow: hidden;
  background: url(../assets/images/back/graylong.png)
    100% no-repeat;
  margin-top: 120px;
  width: 1920px;
  position: relative;
}

.wrap2 {
  z-index: 124;
  width: 129px;
  height: 44px;
  margin: 68px 0 0 896px;
}

.txt11 {
  width: 129px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
}

.wrap3 {
  z-index: 124;
  width: 898px;
  height: 32px;
  margin: 24px 0 0 512px;
}

.info14 {
  width: 898px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 32px;
  text-align: center;
}

.wrap4 {
  z-index: 1;
  width: 1753px;
  height: 68px;
  margin: 281px 0 166px 86px;
}

.pic1 {
  width: 68px;
  height: 68px;
  background: url(../assets/main/leftTrans.png) 100% no-repeat;
}

.pic1:hover {
  background: url(../assets/main/leftArrow.png) 100% no-repeat;
}

.img2 {
  width: 68px;
  height: 68px;
  background: url(../assets/main/rightTrans.png) 100% no-repeat;
}

.img2:hover {
  background: url(../assets/main/rightArrow.png) 100% no-repeat;
}

.wrap5 {
  // z-index: 58;
  height: 683px;
  background: url(../assets/images/back/earth.png)
    100% no-repeat;
  width: 1920px;
  position: absolute;
  left: 0;
  top: 0;
}

.section2 {
  width: 1919px;
  height: 683px;
  background-color: rgba(0, 0, 0, 0.28);
  margin-left: 1px;
}

.layer12 {
  width: 1518px;
  height: 237px;
  margin: 196px 0 0 202px;
}

.block2 {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  background-color: rgba(255, 255, 255, 1);
  height: 237px;
  border: 1px solid rgba(245, 245, 245, 1);
  width: 357px;
}

.block2:hover {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  background-color: rgba(36, 184, 47, 1);
  height: 237px;
  border: 1px solid rgba(245, 245, 245, 1);
  width: 357px;
}

.mod9 {
  width: 239px;
  height: 177px;
  margin: 58px 0 0 116px;
}

.word15 {
  width: 97px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiR;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
  margin-left: 14px;
  display: block;
}

.block2:hover .word15 {
  color: rgba(255, 255, 255, 1);
}

.block2:hover .imgs1 {
  width: 140px;
  height: 144px;
  background: url(../assets/main/whiteone.png) -1px
    0px no-repeat;
  margin-left: 99px;
}
.word16 {
  width: 126px;
  height: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(37, 184, 47, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 72px;
  text-align: left;
  margin-top: 12px;
}

.block2:hover .word16 {
  width: 126px;
  height: 72px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 72px;
  display: block;
  margin: -132px 0 60px 0;
}

.block3 {
  height: 237px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 357px;
}

.block3:hover {
  background-color: rgba(36, 184, 47, 1);
}

.group6 {
  width: 145px;
  height: 117px;
  margin: 58px 0 0 106px;
}

.word17 {
  width: 145px;
  height: 33px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
}

.block3:hover .imgs2 {
  // width: 140px;
  // height: 144px;
  width: 122px;
  height: 132px;
  background: url(../assets/main/whitetwo.png)
    0px -1px no-repeat;
  margin-left: 120px;
}

.block3:hover .word17 {
  color: rgba(255, 255, 255, 1);
}

.info15 {
  width: 126px;
  height: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(37, 184, 47, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 72px;
  text-align: left;
  margin-top: 12px;
  margin-left: 19px;
}

.block3:hover .info15 {
  color: rgba(255, 255, 255, 1);
  margin-top: -120px;
}

.block4 {
  height: 237px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 357px;
}

.block4:hover {
  background-color: rgba(36, 184, 47, 1);
}

.mod10 {
  width: 249px;
  height: 170px;
  margin: 58px 0 0 101px;
}

.txt12 {
  width: 121px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiR;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
  margin-left: 17px;
  display: block;
}

.block4:hover .txt12 {
  color: rgba(255, 255, 255, 1);
}

.block4:hover .imgs3 {
  width: 133px;
  height: 133px;
  background: url(../assets/main/whitethird.png)
    0px 0px no-repeat;
  margin: 4px 0 0 116px;
  // margin-left: 5px;
}
.word18 {
  width: 155px;
  height: 72px;
  overflow-wrap: break-word;
  color: rgba(37, 184, 47, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 72px;
  display: block;
  margin: 12px 0 53px 0;
  margin-left: 5px;
}

.block4:hover .word18 {
  width: 155px;
  height: 72px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 72px;
  display: block;
  margin: -125px 0 53px 0;
}
.block5 {
  height: 237px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 357px;
}

.block5:hover {
  background-color: rgba(36, 184, 47, 1);
}

.box3 {
  width: 286px;
  height: 169px;
  margin: 58px 0 0 60px;
}

.word19 {
  width: 133px;
  height: 33px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
  margin-left: 52px;
}

.block5:hover .word19 {
  color: rgba(255, 255, 255, 1);
}

.block5:hover .imgs4 {
  width: 118px;
  height: 129px;
  background: url(../assets/main/whitefour.png)
    0px -1px no-repeat;
  margin: 7px 0 0 168px;
}

.word20 {
  width: 238px;
  height: 72px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(37, 184, 47, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 72px;
  text-align: left;
  margin-top: 12px;
  margin-left: 10px;
}

.block5:hover .word20 {
  width: 238px;
  height: 72px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 52px;
  letter-spacing: 1.0399999618530273px;
  font-family: AlibabaPuHuiTiM;
  text-align: left;
  white-space: nowrap;
  line-height: 72px;
  display: block;
  margin: -124px 0 52px 0;
  margin-left: 10px;
}

.layer13 {
  z-index: 5;
  height: 599px;
  overflow: hidden;
  // background: url(../assets/images/back/whiteblock.png)
  //   100% no-repeat;
  background: url(../assets/images/partner/partner.png)
    100% no-repeat;
  margin-top: 88px;
  width: 1920px;
  position: relative;
}

.bd2 {
  width: 129px;
  height: 44px;
  margin: 80px 0 0 898px;
}

.word21 {
  width: 129px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
}

.bd3 {
  width: 1263px;
  height: 93px;
  margin: 40px 0 0 329px;
}

.section3 {
  z-index: 9;
  position: relative;
  width: 335px;
  height: 93px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/back/whiteshadow.png) -20px -10px
    no-repeat;
}

.box4 {
  z-index: 10;
  position: absolute;
  left: 5px;
  top: 26px;
  width: 330px;
  height: 41px;
  background: url(../assets/images/partner/zhongchuan.png) -20px -10px
    no-repeat;
}

.section4 {
  z-index: 48;
  position: relative;
  width: 200px;
  height: 93px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/back/whitesmall.png) -20px -10px
    no-repeat;
}

.box5 {
  z-index: 49;
  position: absolute;
  left: 9px;
  top: 4px;
  width: 182px;
  height: 85px;
  background: url(../assets/images/partner/cssc.png) -20px -10px
    no-repeat;
}

.section5 {
  z-index: 45;
  position: relative;
  width: 200px;
  height: 93px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/back/whitesmall.png) -20px -10px
    no-repeat;
}

.block6 {
  z-index: 46;
  position: absolute;
  left: -8px;
  top: -3px;
  width: 217px;
  height: 100px;
  background: url(../assets/images/partner/jingdong.png) -12px -7px
    no-repeat;
}

.section6 {
  z-index: 51;
  position: relative;
  width: 200px;
  height: 93px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/back/whitesmall.png) -20px -10px
    no-repeat;
}

.group7 {
  z-index: 52;
  position: absolute;
  left: 1px;
  top: -28px;
  width: 200px;
  height: 150px;
  background: url(../assets/images/partner/sony.png) -20px
    18px no-repeat;
}

.section7 {
  z-index: 54;
  position: relative;
  width: 200px;
  height: 93px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/back/whitesmall.png) -20px -10px
    no-repeat;
}

.section8 {
  z-index: 55;
  position: absolute;
  left: 17px;
  top: 17px;
  width: 166px;
  height: 60px;
  background: url(../assets/images/partner/jinchang.png) -20px -11px
    no-repeat;
}

.bd4 {
  width: 1264px;
  height: 71px;
  margin: 36px 0 0 329px;
}

.section9 {
  z-index: 12;
  position: relative;
  width: 200px;
  height: 71px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitesma.png) -20px -10px
    no-repeat;
}

.bd5 {
  z-index: 13;
  position: absolute;
  left: -7px;
  top: 5px;
  width: 207px;
  height: 63px;
  background: url(../assets/images/partner/nanfang.png) -13px -10px
    no-repeat;
}

.section10 {
  z-index: 36;
  position: relative;
  width: 200px;
  height: 71px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitesma.png) -20px -10px
    no-repeat;
}

.outer10 {
  z-index: 37;
  position: absolute;
  left: -32px;
  top: -6px;
  width: 254px;
  height: 83px;
  background: url(../assets/images/partner/ryoka.png)
    12px -4px no-repeat;
}

.section11 {
  z-index: 39;
  position: relative;
  width: 200px;
  height: 71px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitesma.png) -20px -10px
    no-repeat;
}

.mod11 {
  z-index: 40;
  position: absolute;
  left: 1px;
  top: -59px;
  width: 200px;
  height: 200px;
  background: url(../assets/images/partner/inspur.png) -20px
    50px no-repeat;
}

.section12 {
  z-index: 42;
  position: relative;
  width: 200px;
  height: 71px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitesma.png) -20px -10px
    no-repeat;
}

.pic2 {
  z-index: 43;
  position: absolute;
  left: -20px;
  top: -10px;
  width: 240px;
  height: 111px;
}

.section13 {
  z-index: 15;
  position: relative;
  width: 200px;
  height: 71px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitesma.png) -20px -10px
    no-repeat;
}

.outer11 {
  z-index: 16;
  position: absolute;
  left: -2px;
  top: 0;
  width: 214px;
  height: 77px;
  background: url(../assets/images/partner/jichang.png) -17px -10px
    no-repeat;
}

.bd6 {
  width: 1262px;
  height: 117px;
  margin: 36px 0 82px 337px;
}

.mod12 {
  z-index: 18;
  position: relative;
  width: 122px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitefang.png) -20px -10px
    no-repeat;
}

.section14 {
  z-index: 19;
  position: absolute;
  left: 0;
  top: -4px;
  width: 127px;
  height: 122px;
  background: url(../assets/images/partner/geesun.png) -20px -5px
    no-repeat;
}

.mod13 {
  z-index: 21;
  position: relative;
  width: 122px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitefang.png) -20px -10px
    no-repeat;
}

.main2 {
  z-index: 22;
  position: absolute;
  left: 13px;
  top: 31px;
  width: 96px;
  height: 56px;
  background: url(../assets/images/partner/csscsma.png) -20px -11px
    no-repeat;
}

.mod14 {
  z-index: 24;
  position: relative;
  width: 122px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitefang.png) -20px -10px
    no-repeat;
}

.section15 {
  z-index: 25;
  position: absolute;
  left: -12px;
  top: -24px;
  width: 155px;
  height: 167px;
  background: url(../assets/images/partner/crsc.png) -8px
    15px no-repeat;
}

.mod15 {
  z-index: 27;
  position: relative;
  width: 122px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitefang.png) -20px -10px
    no-repeat;
}

.mod16 {
  z-index: 28;
  position: absolute;
  left: 2px;
  top: 6px;
  width: 118px;
  height: 105px;
  background: url(../assets/images/partner/geely.png) -11px
    1px no-repeat;
}

.mod17 {
  z-index: 30;
  position: relative;
  width: 172px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitemid.png) -20px -10px
    no-repeat;
}

.block7 {
  z-index: 31;
  position: absolute;
  left: -15px;
  top: -5px;
  width: 202px;
  height: 143px;
  background: url(../assets/images/partner/hik.png) -5px -5px
    no-repeat;
}

.mod18 {
  z-index: 33;
  position: relative;
  width: 172px;
  height: 117px;
  border-radius: 12px;
  overflow: hidden;
  background: url(../assets/images/partner/whitemid.png) -20px -10px
    no-repeat;
}

.bd7 {
  z-index: 34;
  position: absolute;
  left: -3px;
  top: 15px;
  width: 179px;
  height: 90px;
  background: url(../assets/images/partner/iflytek.png) -17px -10px
    no-repeat;
}

.pic3 {
  z-index: 6;
  position: absolute;
  left: 173px;
  top: 53px;
  width: 1638px;
  height: 546px;
}

.info16 {
  width: 193px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
  margin: 120px 0 0 864px;
}

.layer14 {
  height: 472px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 1181px;
  margin: 60px 0 0 370px;
}

.bd8 {
  width: 1092px;
  height: 472px;
}

.group8 {
  // z-index: 233;
  position: relative;
  width: 576px;
  height: 472px;
  overflow: hidden;
  background: url(../assets/images/back/grayfang.png)
    100% no-repeat;
}

.pic4 {
  // z-index: 234;
  width: 100%;
  height: 100%;
  background: url(../assets/main/feiji.png)
    100% no-repeat;
}

.group9 {
  width: 387px;
  height: 336px;
  margin-top: 60px;
}

.word22 {
  width: 193px;
  height: 33px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(39, 39, 39, 1);
  font-size: 24px;
  letter-spacing: 0.47999998927116394px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
}

.word23 {
  width: 387px;
  height: 218px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 30px;
  text-align: left;
  margin-top: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info17 {
  width: 387px;
  height: 120px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  letter-spacing: 1.3333333730697632px;
  font-family: AlibabaPuHuiTiR;
  line-height: 30px;
  text-align: left;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section16 {
  height: 57px;
  border: 1px solid rgba(37, 184, 47, 1);
  box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
  margin-top: 18px;
  width: 145px;
  cursor: pointer;
}

.section16:hover {
  background-color: rgba(37, 184, 47, 1);
}

.info18 {
  width: 73px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(37, 184, 47, 1);
  font-size: 18px;
  letter-spacing: 0.36000001430511475px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 25px;
  text-align: left;
  margin: 16px 0 0 36px;
}
.section16:hover .info18 {
  color: rgba(255, 255, 255, 1);
}

.layer15 {
  height: 445px;
  background-color: rgba(37, 184, 47, 1);
  margin-top: 120px;
  width: 1920px;
}

.layer16 {
  width: 469px;
  height: 185px;
  margin: 116px 0 0 726px;
}

.info19 {
  width: 469px;
  height: 65px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  letter-spacing: 4.800000190734863px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 65px;
  text-align: left;
}

.wrap6 {
  height: 72px;
  border-radius: 36px;
  background-color: rgba(255, 255, 255, 1);
  width: 240px;
  cursor: pointer;
  margin: 48px 0 0 114px;
}

.txt13 {
  width: 129px;
  height: 44px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(40, 40, 40, 1);
  font-size: 32px;
  letter-spacing: 0.6399999856948853px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 44px;
  text-align: left;
  margin: 14px 0 0 56px;
}

.layer17 {
  height: 526px;
  background-color: rgba(38, 38, 38, 1);
  width: 1920px;
}

.group10 {
  width: 1520px;
  height: 362px;
  margin: 81px 0 0 201px;
}

.group11 {
  width: 1015px;
  height: 36px;
  margin-left: 128px;
}

.word24 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info20 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 156px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt14 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word25 {
  width: 38px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 0.8571428656578064px;
  font-family: AlibabaPuHuiTiM;
  line-height: 36px;
  text-align: left;
  margin-left: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word26 {
  width: 87px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 21px;
  letter-spacing: 1px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 36px;
  text-align: left;
  margin-left: 388px;
}

.group12 {
  width: 1138px;
  height: 56px;
  margin: 20px 0 0 128px;
}

.outer12 {
  width: 73px;
  height: 56px;
}

.word27 {
  width: 73px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
}

.word28 {
  width: 64px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.outer13 {
  width: 67px;
  height: 56px;
  margin-left: 121px;
}

.word29 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
}

.word30 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.word31 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-left: 87px;
}

.info21 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-left: 87px;
}

.txt15 {
  width: 210px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 16px 0 0 359px;
}

.group13 {
  width: 1262px;
  height: 88px;
  margin: 8px 0 0 128px;
}

.box6 {
  width: 77px;
  height: 88px;
}

.word32 {
  width: 68px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
}

.info22 {
  width: 77px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.info23 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.box7 {
  width: 67px;
  height: 56px;
  margin-left: 117px;
}

.info24 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
}

.txt16 {
  width: 67px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}

.box8 {
  width: 334px;
  height: 84px;
  margin-left: 667px;
}

.word33 {
  width: 334px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info25 {
  width: 220px;
  height: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  line-height: 36px;
  text-align: left;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word34 {
  width: 68px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  letter-spacing: 0.761904776096344px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin: 8px 0 0 128px;
}

.word35 {
  width: 632px;
  height: 24px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  letter-spacing: 1.75px;
  font-family: AlibabaPuHuiTiR;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  margin: 81px 0 0 443px;
}

.block8 {
  z-index: 122;
  height: 551px;
  background: url(../assets/main/earthone.png)
    100% no-repeat;
  width: 488px;
  margin-right: 28px;
  // position: absolute;
  // left: 200px;
  // top: 3329px;
}

.block8:hover {
  // background: url();
  background: rgba(255, 255, 255, 1) !important;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
}

.mod19 {
  width: 322px;
  height: 378px;
  margin: 96px 0 0 83px;
}

.txt17 {
  width: 125px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
  margin-left: 89px;
}

.txt18 {
  width: 322px;
  height: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block8:hover .txt18 {
  color: rgba(102, 102, 102, 1);
}

.info26 {
  width: 322px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block8:hover .info26 {
  color: rgba(102, 102, 102, 1);
}

.info27 {
  width: 322px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block8:hover .info27 {
  color: rgba(102, 102, 102, 1);
}

.block9 {
  z-index: 123;
  height: 551px;
  background: url(../assets/main/earthtwo.png)
    100% no-repeat;
  width: 488px;
  margin-right: 28px;
  // position: absolute;
  // left: 716px;
  // top: 3329px;
}

.block9:hover {
  z-index: 123;
  height: 551px;
  // background: url();
  background: rgba(255, 255, 255, 1) !important;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 488px;
}

.outer14 {
  width: 322px;
  height: 354px;
  margin: 96px 0 0 83px;
}

.word36 {
  width: 125px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
  margin-left: 107px;
}

.block8:hover .word36 {
  color: rgba(40, 40, 40, 1);
}

.block9:hover .word36 {
  color: rgba(40, 40, 40, 1);
}

.block10:hover .word36 {
  color: rgba(40, 40, 40, 1);
}

.info28 {
  width: 322px;
  height: 64px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block9:hover .info28 {
  color: rgba(102, 102, 102, 1);
}

.word37 {
  width: 322px;
  height: 192px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block9:hover .word37 {
  color: rgba(102, 102, 102, 1);
}

.block10 {
  z-index: 124;
  height: 551px;
  background: url(../assets/main/earththird.png)
    100% no-repeat;
  width: 488px;
  margin-right: 28px;
  // position: absolute;
  // left: 1233px;
  // top: 3329px;
}

.block10:hover {
  // background: url();
  background: rgba(255, 255, 255, 1) !important;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
}

.main3 {
  width: 322px;
  height: 322px;
  margin: 96px 0 0 83px;
}

.word38 {
  width: 117px;
  height: 38px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(40, 40, 40, 1);
  font-size: 28px;
  letter-spacing: 0.5600000023841858px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 38px;
  text-align: left;
  margin-left: 99px;
}

.word39 {
  width: 322px;
  height: 128px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block10:hover .word39 {
  width: 322px;
  height: 128px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word40 {
  width: 322px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block10:hover .word40 {
  width: 322px;
  height: 96px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: AlibabaPuHuiTiR;
  line-height: 32px;
  text-align: left;
  margin-top: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block11 {
  // z-index: 219;
  position: absolute;
  left: -981px;
  top: 5148px;
  width: 1181px;
  height: 472px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
}

.img3 {
  // z-index: 220;
  position: absolute;
  left: 166px;
  top: 5351px;
  width: 68px;
  height: 68px;
  background: url(../assets/main/leftgreen.png)
    100% no-repeat;
}

.img3:hover {
  background: url(../assets/main/leftArrow.png) 100% no-repeat;
}

.pic5 {
  z-index: 3;
  position: absolute;
  left: 1689px;
  top: 5350px;
  width: 68px;
  height: 68px;
  background: url(../assets/main/rightgreen.png)
    100% no-repeat;
}

.pic5:hover {
  background: url(../assets/main/rightArrow.png) 100% no-repeat;
}

.block12 {
  z-index: 2;
  height: 472px;
  border: 1px solid rgba(245, 245, 245, 1);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  width: 1181px;
  position: absolute;
  left: 1720px;
  top: 5148px;
}

.img4 {
  width: 576px;
  height: 472px;
  // margin-left: 3px;
}
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
</style>
