import { render, staticRenderFns } from "./footInfo.vue?vue&type=template&id=db17b172"
import script from "./footInfo.vue?vue&type=script&lang=js"
export * from "./footInfo.vue?vue&type=script&lang=js"
import style0 from "./footInfo.vue?vue&type=style&index=0&id=db17b172&prod&rel=stylesheet%2Fscss&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports