import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title:'首页',
      content: {
        keywords: '仓储物流，数字化企业，erp系统，订单处理，',
        description: '一站式数据集成支撑企业战略   执行全领域办公协同助力企业数字化转型升级，助力仓储物流数智化'
      }
    }
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('../views/system/index.vue'),
    meta: {
      title:'服务体系',
      content: {
        keywords: '全流程，售后服务，定制化，产品培训，及时响应',
        description: '整体全流程把控，管家式服务保障，为企业提供简洁高效的解决方案'
      }
    }
  },
  {
    path: '/instance',
    name: 'instance',
    component: () => import('../views/instance/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue'),
    meta: {
      title:'关于松云',
      content: {
        keywords: '仓储物流，电商，erp，订单管理系统',
        description: '掌握制造、物流、电商和通信等领域知识，贯通软件、算法及设备，提供一体化解决方案'
      }
    }
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/register/index.vue')
  // },
  {
    path: '/product/erp',
    name: 'erp',
    component: () => import('../views/product/erp.vue'),
    meta: {
      title:'产品介绍-ERP',
      content: {
        keywords: 'erp,企业资源管理,电商erp,跨境电商erp,零售erp',
        description: '为企业各部门打通信息沟通壁垒，保障各运营部门信息传递、沟通流畅，为财务、供应链提供坚实的软件基础，为管理者运筹帷幄提供实时、生动、精准的智能大数据分析'
      }
    }
  },
  {
    path: '/product/crm',
    name: 'crm',
    component: () => import('../views/product/crm.vue'),
    meta: {
      title:'产品介绍-CRM',
      content: {
        keywords: 'crm，营销获客，客户管理，销售管理，',
        description: '线索到合同签订全流程可视化透明跟进分析客户成交成功率大大增加合作概率'
      }
    }
  },
  {
    path: '/product/mall',
    name: 'mall',
    component: () => import('../views/product/mall.vue'),
    meta: {
      title:'产品介绍-MALL',
      content: {
        keywords: '订单管理系统，电商零售，数字化运营，智慧营销，',
        description: '云商城线上部署，多平台协同开发新零售线下部署，智能设备一体化'
      }
    }
  },
  {
    path: '/product/wms',
    name: 'wms',
    component: () => import('../views/product/wms.vue'),
    meta: {
      title:'产品介绍-WMS',
      content: {
        keywords: 'wms，仓储系统，智能仓储管理系统，智能硬件，出库效率',
        description: '仓库管理系统以仓库作业技术的整合为主要目标，全渠道管理，多层级多仓多货主多模式，根据不同业务提供差异化的行业解决方案'
      }
    }
  },
  {
    path: '/product/tms',
    name: 'tms',
    component: () => import('../views/product/tms.vue'),
    meta: {
      title:'产品介绍-TMS',
      content: {
        keywords: 'tms，智能物流，跨境物流，运输物流，第三方物流系统',
        description: '运用先进算法，解决企业运输线路、多订单追踪管理、云端智能调度、账单管理、可视化运输过程等场景中的任何问题，减少企业压力，增加行业竞争力'
      }
    }
  },
  {
    path: '/product/scm',
    name: 'scm',
    component: () => import('../views/product/scm.vue'),
    meta: {
      title:'产品介绍-SCM',
      content: {
        keywords: 'scm，供应商管理，供应链管理，合同管理，采购系统',
        description: '从生产到运输，企业通过改善供应链关系，整合信息流、物流、资金流，有效助力企业更具独特竞争优势'
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
