<template>
    <div>
        <span class="per-txt27">全流程保障</span>
      <div class="per-mod27 flex-row">
        <img
          class="per-img16"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/proone.png"
        />
        <img
          class="per-label1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowright.png"
        />
        <img
          class="per-img17"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/protwo.png"
        />
        <img
          class="per-icon1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowright.png"
        />
        <img
          class="per-pic9"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/prothird.png"
        />
      </div>
      <div class="per-mod28 flex-row justify-between">
        <span class="per-word52">前期调研</span>
        <span class="per-info28">方案设计</span>
        <span class="per-info29">上线准备</span>
      </div>
      <div class="per-mod29 flex-row justify-between">
        <span class="per-infoBox6">客户需求沟通<br />现场业务了解梳理</span>
        <span class="per-paragraph13"
          >量身打造实施方案<br />系统操作与人员优化</span
        >
        <span class="per-infoBox7">基础数据初始化准备<br />系统整理与规划</span>
      </div>
      <div class="per-mod30 flex-row">
        <img
          class="per-pic10"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/profour.png"
        />
        <img
          class="per-label2"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowleft.png"
        />
        <img
          class="per-img18"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/profive.png"
        />
        <img
          class="per-label3"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowleft.png"
        />
        <img
          class="per-img19"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/prosix.png"
        />
      </div>
      <div class="per-mod31 flex-row justify-between">
        <span class="per-info30">售后跟踪</span>
        <span class="per-info31">系统培训</span> <span class="per-txt28">专业实施</span>
      </div>
      <div class="per-mod32 flex-row justify-between">
        <span class="per-paragraph14"
          >线上线下&nbsp;定期回访<br />管家式跟踪服务快速响应</span
        >
        <span class="per-paragraph15"
          >点对点和重点岗位培训<br />协助角色权限分配</span
        >
        <span class="per-paragraph16"
          >项目经理全程跟踪进度<br />确保系统切换无忧</span
        >
      </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      type: 0,
      constants: {},
    };
  },
  methods: {
    onClick_1() {
      alert(1);
    },
    onClick_2() {
      alert(1);
    },
    onClick_3() {
      alert(1);
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.per-txt27 {
  width: 181px;
  height: 50px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(40, 40, 40, 1);
  font-size: 36px;
  letter-spacing: 0.7200000286102295px;
  font-family: AlibabaPuHuiTiM;
  white-space: nowrap;
  line-height: 50px;
  text-align: left;
  margin: 120px 0 0 869px;
}
.per-mod27 {
  width: 1236px;
  height: 264px;
  margin: 32px 0 0 342px;
  .per-img16 {
    width: 340px;
    height: 264px;
  }
  .per-label1 {
    width: 46px;
    height: 25px;
    margin: 120px 0 0 28px;
  }
  .per-img17 {
    width: 340px;
    height: 264px;
    margin-left: 34px;
  }
  .per-icon1 {
    width: 46px;
    height: 25px;
    margin: 120px 0 0 34px;
  }
  .per-pic9 {
    width: 340px;
    height: 264px;
    margin-left: 28px;
  }
}
.per-mod28 {
  width: 969px;
  height: 25px;
  margin-left: 476px;
  .per-word52 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
  .per-info28 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
  .per-info29 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
}
.per-mod29 {
  width: 1141px;
  height: 48px;
  margin: 6px 0 0 390px;
  .per-infoBox6 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .per-paragraph13 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .per-infoBox7 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.per-mod30 {
  width: 1236px;
  height: 264px;
  margin: 40px 0 0 342px;
  .per-pic10 {
    width: 340px;
    height: 264px;
  }
  .per-label2 {
    width: 46px;
    height: 25px;
    margin: 120px 0 0 28px;
  }
  .per-img18 {
    width: 340px;
    height: 264px;
    margin-left: 34px;
  }
  .per-label3 {
    width: 46px;
    height: 25px;
    margin: 120px 0 0 34px;
  }
  .per-img19 {
    width: 340px;
    height: 264px;
    margin-left: 28px;
  }
}
.per-mod31 {
  width: 969px;
  height: 25px;
  margin-left: 476px;
  .per-info30 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
  .per-info31 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
  .per-txt28 {
    width: 73px;
    height: 25px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(39, 39, 39, 1);
    font-size: 18px;
    letter-spacing: 0.36000001430511475px;
    font-family: AlibabaPuHuiTiR;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }
}
.per-mod32 {
  width: 1141px;
  height: 48px;
  margin: 6px 0 0 390px;
  .per-paragraph14 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .per-paragraph15 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .per-paragraph16 {
    width: 245px;
    height: 48px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    letter-spacing: 1.1666666269302368px;
    font-family: AlibabaPuHuiTiR;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style src="../assets/css/common.css" />